/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {NavLink} from "react-router-dom";
import "./index.css";

const ProductsList = () => {

  const path = window.location.pathname;
  return (
    <div className="product-list">
      <div id="chocolates">
        <NavLink
          to="/chocolates#chocolates"
          className={path.includes('productos') || path.includes('chocolates') ? 'list-highlight' : ''}
        >
          chocolates
        </NavLink>
      </div>
      <div id="alfajores">
        <NavLink
          to="/alfajores#alfajores"
          className={path.includes('alfajores') ? 'list-highlight' : ''}
        >
          alfajores
        </NavLink>
      </div>
      <div id="confituras">
        <NavLink
          to="/confituras#confituras"
          className={path.includes('confituras') ? 'list-highlight' : ''}
        >
          confituras
        </NavLink>
      </div>
      <div id="submarinos">
        <NavLink
          to="/submarinos#submarinos"
          className={path.includes('submarinos') ? 'list-highlight' : ''}
        >
          submarinos
        </NavLink>
      </div>
      {/*<div id="tabletas">
        <NavLink
          to="/tabletas#tabletas"
          className={path.includes('tabletas') ? 'list-highlight' : ''}
        >
          tabletas
        </a>
      </div>*/}
    </div>
  );
};

export default ProductsList;
