/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

const NotFound = () => {
  return (
    <div className="productos">
      <div className="top-section p-5 mt-1 mt-md-4" style={{height: 'initial'}}>
        <span className="familia-la-pinocha mt-3">¡Ups, página no encontrada!</span>
        <span className="conoce-nuestra-historia-1">
          Te invitamos a recorrer nuestro sitio web <br />
          desde el principio:
        </span>
        <div className="m-3">
          <a href="/">Ir al Inicio</a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
