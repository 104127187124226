/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { SHOPY_URL } from "../../models/constants";
import ProductsList from "../../components/products-list";

const isDesktop = () => window.innerWidth > 780;

const products = [
  {
    title: "Alfajor de Dulce de Leche",
    description: "",
  },
  {
    title: "Alfajor de Dulce de Leche y Coco",
    description: "",
  },
];

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
  backgroundColor: 'rgba(255,255,255,.5)',
  borderRadius: '50%',
};

const ProductosAlfajores = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const goto = (imageToShow) => {
    setSelectedImage(imageToShow);
  };

  const carousel = (
    <Carousel
      selectedItem={selectedImage}
      width={isDesktop() ? '50%' : '100%'}
      showIndicators={false}
      showThumbs={false}
      infiniteLoop={true}
      showStatus={false}
      showArrows={isDesktop()}
      autoPlay={!isDesktop()}
      onChange={(newValue) => setSelectedImage(newValue)}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <div onClick={onClickHandler}
               className="carrousel-arrow"
               alt=""
               style={{
                 backgroundImage: `url(${
                   require("../../img/left-carrousel-arrow.png").default
                 })`,
                 backgroundSize: "cover",
                 left: 35,
                 marginRight: 35,
                 ...arrowStyles,
               }}
          />
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <div
            onClick={onClickHandler}
            className="carrousel-arrow"
            alt=""
            style={{
              backgroundImage: `url(${
                require("../../img/right-carrousel-arrow.png").default
              })`,
              backgroundSize: "cover",
              ...arrowStyles,
              right: 35,
            }}
          />
        )
      }
    >
      <img
        src={require("../../img/productos/alfajores/Alfajor-dulce-de-leche.jpg").default}
        alt="chocolates"
      />
      <img
        src={require("../../img/productos/alfajores/Alfajor-dulce-de-leche-y-coco.jpg").default}
        alt="chocolates"
      />
    </Carousel>
  );

  return (
    <div className="productos">
      <div className="top-section">
        <span className="familia-la-pinocha">¿Cuál de todos elegís?</span>
        <span className="conoce-nuestra-historia-1">
          Elaboramos más de 60 sabores diferentes. <br />
          Te mostramos nuestros preferidos
        </span>
      </div>
      <div>
        <div className="productos-rose">
          <ProductsList/>
          <div className="banners">
            {carousel}
          </div>
        </div>
        <div className="white-background">
          <div className="description-chocolate">
            <span className="choco-name mb-3">
              {products[selectedImage].title}
            </span>
            {products[selectedImage].description && (
              <span className="choco-description text-center">
                {products[selectedImage].description}
              </span>
            )}
            <a
              href={SHOPY_URL}
              target="_blank"
              className="margin-queres-conocer footer-submit text-center mt-3"
              rel="noreferrer"
            >
              ¿QUERÉS CONOCER MÁS? DESCUBRILO ACÁ
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductosAlfajores;
