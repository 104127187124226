import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-slideshow-image/dist/styles.css";
import "./index.mobile.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import ProductosChocolates from "./pages/productos-chocolates";
import KnowUs from "./pages/conocenos";
import Branches from "./pages/sucursales";
import Franchise from "./pages/franquicias";
import Header from "./header";
import Footer from "./footer";
import NotFound from "./pages/not-found";
import ProductosAlfajores from "./pages/productos-alfajores";
import ProductosConfituras from "./pages/productos-confituras";
import ProductosSubmarinos from "./pages/productos-submarinos";

ReactDOM.render(
  /*<BrowserRouter forceRefresh basename="/ar">*/
  <BrowserRouter>
    <div className="container-fluid full-width">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="productos" element={<ProductosChocolates />} />
        <Route path="chocolates" element={<ProductosChocolates />} />
        <Route path="alfajores" element={<ProductosAlfajores />} />
        <Route path="confituras" element={<ProductosConfituras />} />
        <Route path="submarinos" element={<ProductosSubmarinos />} />
        <Route path="conocenos" element={<KnowUs />} />
        <Route path="sucursales" element={<Branches />} />
        <Route path="franquicia" element={<Franchise />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
