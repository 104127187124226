import { useState } from "react";
import { NavLink } from "react-router-dom";
import {SHOPY_URL} from "../models/constants";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const NavLinkShopy = ({ mobile }) => (
    <a
      href={SHOPY_URL}
      target="_blank"
      className="link-header"
      onClick={() => setShowMenu(!showMenu)}
    >
      COMPRÁ ONLINE
    </a>
  );
  const NavLinkProductos = ({ mobile }) => (
    <NavLink
      to="/productos"
      className={({ isActive }) =>
        `${mobile ? "" : "link-header"} ${isActive ? "bold" : ""}`
      }
      onClick={() => setShowMenu(!showMenu)}
    >
      PRODUCTOS
    </NavLink>
  );
  const NavLinkConocenos = ({ mobile }) => (
    <NavLink
      to="/conocenos"
      className={({ isActive }) =>
        `${mobile ? "" : "link-header"} ${isActive ? "bold" : ""}`
      }
      onClick={() => setShowMenu(!showMenu)}
    >
      CONOCENOS
    </NavLink>
  );
  const NavLinkSucursales = ({ mobile }) => (
    <NavLink
      to="/sucursales"
      className={({ isActive }) =>
        `${mobile ? "" : "link-header"} ${isActive ? "bold" : ""}`
      }
      onClick={() => setShowMenu(!showMenu)}
    >
      SUCURSALES
    </NavLink>
  );
  const NavLinkFranquicia = ({ mobile }) => (
    <NavLink
      to="/franquicia"
      className={({ isActive }) =>
        `${mobile ? "" : "link-header"} ${isActive ? "bold" : ""}`
      }
      onClick={() => setShowMenu(!showMenu)}
    >
      FRANQUICIA
    </NavLink>
  );

  const logo = require("../img/logo.png").default;
  const logo2 = require("../img/logo@2x.png").default;
  const logo3 = require("../img/logo@3x.png").default;

  return (
    <div className="pre-header">
      <div className="header">
        <div className="logo">
          <a href="/ar">
            <img
              src={logo}
              srcSet={`${logo2} 2x, ${logo3} 3x`}
              alt="La Pinocha logo"
            ></img>
          </a>
        </div>
        <input type="checkbox" id="country-selector-check" />
        <div className="country-selector">
          <label for="country-selector-check">
            <img className="flag" src={require("../img/ar-flag.png").default} />
            <img className="chevron" src={require("../img/chevron-down.png").default} />
          </label>
          <div className="country-selector-list">
            <a href="/es" className="country-selector-list-option">
              <img className="flag" src={require("../img/sp-flag.png").default} /> <span>España</span>
            </a>
          </div>
        </div>
        <div
          className="burger-menu float-right"
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="blank-separator"></div>
          <img
            src={require("../img/burger-menu.png").default}
            alt=""
            className="icon-1"
          />
        </div>
        <div className="nav-menu">
          <ul>
            <li className="list-elements">
              <NavLinkShopy></NavLinkShopy>
              <NavLinkProductos></NavLinkProductos>
              <NavLinkConocenos></NavLinkConocenos>
              <NavLinkSucursales></NavLinkSucursales>
              <NavLinkFranquicia></NavLinkFranquicia>

              <div className="social-icon">
                <a
                  href="https://www.instagram.com/lapinochachocolates"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../img/instagram-white.png").default}
                    alt=""
                    className="icon-1"
                  />
                </a>
                <a
                  href="https://es-la.facebook.com/lapinochachocolates"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../img/facebook-white.png").default}
                    alt=""
                    className="icon-2"
                  />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="header-bottom-margin"></div>
      <div
        className={`menu-mobile ${showMenu ? "d-flex flex-column" : "d-none"}`}
      >
        <div className="par">
          <NavLinkShopy mobile={true}></NavLinkShopy>
        </div>
        <div className="impar">
          <NavLinkProductos mobile={true}></NavLinkProductos>
        </div>
        <div className="par">
          <NavLinkConocenos mobile={true}></NavLinkConocenos>
        </div>
        <div className="impar">
          <NavLinkSucursales mobile={true}></NavLinkSucursales>
        </div>
        <div className="par">
          <NavLinkFranquicia mobile={true}></NavLinkFranquicia>
        </div>
      </div>
    </div>
  );
};

export default Header;
