class Helpers {
	/*
	*
	* Method to detect mobile devices
	 */
	isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}
}

//unregister();

export default Helpers;
