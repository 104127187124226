import React, {useEffect, useRef} from "react";
import markerIcon from "../../img/marker-pin.png";
import userMarkerIcon from "../../img/user-location-icon.png";
import whatsAppIcon from "../../img/icon-wapp.svg";
import telephoneIcon from "../../img/icon-phone.svg";
import flagRoundedIcon from "../../img/flag-rounded.svg";


export const addMarkers = ({locations, map, callback}: {
  locations: ReadonlyArray<google.maps.LatLngLiteral>;
  map: google.maps.Map | null | undefined;
  callback: () => void;
}) => {
  const image = {
    url: markerIcon,
    // This marker is 20 pixels wide by 32 pixels high.
    // size: new google.maps.Size(50, 50),
    // The origin for this image is (0, 0).
    // origin: new google.maps.Point(0, 0),
    // The anchor for this image is the base of the flagpole at (0, 32).
    // anchor: new google.maps.Point(0, 32),
  };

  locations.map((position, i) => {
    const marker = new google.maps.Marker({
      position, map, icon: image,
    });
    const infowindow = new google.maps.InfoWindow({
      content: `<div class="infowindow"><div class="infowindow-header"><img class="infowindow-header-icon" src="${flagRoundedIcon}" /> Sucursal</div><div class="infowindow-content"><div class="infowindow-title">${position.name}</div><div class="infowindow-address">${position.address}</div>${!position.whatsappLink && !position.telephone ? null :
        position.whatsappLink ?
          `<div class="infowindow-phone"><a href="https://wa.me/${position.whatsappLink}"} target="_blank"><img src="${whatsAppIcon}" /> ${position.telephone}</a></div>`
          : `<div class="infowindow-phone"><a href="tel://${position.telephone}" target="_blank"><img src="${telephoneIcon}" /> ${position.telephone}</a></div>`
      }</div></div>`,
      ariaLabel: "Info",
    });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
      });
    });

    i === locations.length - 1 && callback();
  });
}

// Función para calcular distancias entre dos puntos
const calculateDistance = (lat1, lng1, lat2, lng2) => {
  // Función para calcular la distancia entre dos puntos geográficos usando la fórmula de Haversine.
  const earthRadius = 6371; // Radio de la Tierra en kilómetros.

  // Convertir las coordenadas de grados a radianes.
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lng1Rad = (lng1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lng2Rad = (lng2 * Math.PI) / 180;

  // Calcular las diferencias de latitud y longitud.
  const latDiff = lat2Rad - lat1Rad;
  const lngDiff = lng2Rad - lng1Rad;

  // Calcular la distancia usando la fórmula de Haversine.
  const a =
    Math.sin(latDiff / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lngDiff / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
}

// Función para encontrar la location más cercana a un punto dado
const bringTheCloser = (lat, lng, locations) => {
  if (locations.length === 0) {
    return null; // Si no hay ubicaciones en el array, retornar null.
  }

  let closestLocation = locations[0]; // Inicializamos con la primera ubicación.
  let closestDistance = calculateDistance(
    lat,
    lng,
    closestLocation.lat,
    closestLocation.lng
  );

  // Recorremos las demás ubicaciones para encontrar la más cercana.
  for (let i = 1; i < locations.length; i++) {
    const location = locations[i];
    const distance = calculateDistance(lat, lng, location.lat, location.lng);

    if (distance < closestDistance) {
      closestLocation = location;
      closestDistance = distance;
    }
  }

  return closestLocation;
}

export const GoogleMaps = ({mapConfig, locations, className, userLocationMarker}) => {
  const ref = useRef();

  useEffect(() => {
    // Display the map
    if (ref.current && window.google) {
      const map = new window.google.maps.Map(ref.current, {
        center: mapConfig?.center,
        zoom: mapConfig?.zoom,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        // zoomControl: false,
        // maxZoom: 8,
        styles: [
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }
        ],
      });

      // Displays single markers on map when called
      // @ts-ignore
      if (locations && locations.length) {
        addMarkers({
          locations, map, callback: () => {
            const bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < locations.length; i++) {
              bounds.extend(locations[i]);
            }

            if (mapConfig.setBounds && !userLocationMarker) {
              map.fitBounds(bounds, {top: 280, right: 0, left: 0});
            }
          }
        });
      }

      if (userLocationMarker) {
        const userLocation = new google.maps.Marker({
          position: userLocationMarker,
          map,
          icon: {
            url: userMarkerIcon,
            // This marker is 20 pixels wide by 32 pixels high.
            // size: new google.maps.Size(50, 50),
            // The origin for this image is (0, 0).
            // origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            // anchor: new google.maps.Point(0, 32),
          },
        });

        const infowindow = new google.maps.InfoWindow({
          content: `<div class="infowindow"><div class="infowindow-header"><img class="infowindow-header-icon" src="${flagRoundedIcon}" /> Aquí estás tú</div></div></div>`,
          ariaLabel: "Info",
        });

        userLocation.addListener("click", () => {
          infowindow.open({
            anchor: userLocation,
            map,
          });
        });

        map.setCenter(userLocationMarker);
        const closestLocation = bringTheCloser(userLocationMarker.lat, userLocationMarker.lng, locations);
        console.log("Ubicación más cercana:", closestLocation);

        if (closestLocation) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(userLocationMarker);
          bounds.extend(closestLocation);
          map.fitBounds(bounds, {top: 280, right: 0, left: 0});
        }
      }
    }
  }, [ref, locations]);

  return (<div
    ref={ref}
    className="map"
  />);
};
