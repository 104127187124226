import Sucursal from "./sucursal";
import Helpers from "../../modules/helpers";
import {useEffect, useRef, useState} from "react";
import {Wrapper} from "@googlemaps/react-wrapper";
import {Spinner} from "react-bootstrap";
import {GoogleMaps} from "../../components/map";

const sucursales = [{
  zone: "Capital Federal", sucursales: [{
    name: "Belgrano",
    lat: -34.562695,
    lng: -58.455542,
    address: "Echeverría 2383",
    openAt: "Abierto todos los días",
    telephone: "11 2401 7412",
    whatsappLink: "541124017412",
  }, {
    name: "Villa Urquiza",
    lat: -34.575422,
    lng: -58.484547,
    address: "Triunvirato 4531",
    openAt: "Abierto todos los días",
    telephone: "11 3702 8827",
    whatsappLink: "541137028827",
  }, {
    name: "Colegiales",
    lat: -34.568065,
    lng: -58.449195,
    address: "Av. Cabildo 1306",
    openAt: "Abierto todos los días",
    telephone: "11 2250 2432",
    whatsappLink: "541122502432",
  }, {
    name: "Caballito",
    lat: -34.615112,
    lng: -58.430033,
    address: "Río de Janeiro 5",
    openAt: "Abierto todos los días",
    telephone: "11 2389 8626",
    whatsappLink: "541123898626",
  }, {
    name: "Palermo",
    lat: -34.586617,
    lng: -58.413758,
    address: "Av. Santa Fe 3476",
    openAt: "Abierto todos los días",
    telephone: "11 3941 1647",
    whatsappLink: "541139411647",
  }, {
    name: "Palermo Soho",
    lat: -34.589631,
    lng: -58.428792,
    address: "Honduras 4866",
    openAt: "Abierto todos los días",
    telephone: "11 2358 8765",
    whatsappLink: "541123588765",
  }, {
    name: "Villa del Parque",
    lat: -34.602385,
    lng: -58.494523,
    address: "Cuenca 2989",
    openAt: "Abierto todos los días",
    telephone: "11 2387 8139",
    whatsappLink: "541123878139",
  }, {
    name: "Cañitas",
    lat: -34.565003,
    lng: -58.438765,
    address: "Soldado de la Independencia 1131",
    openAt: "Abierto todos los días",
    telephone: "11 5632 2530",
    whatsappLink: "5491156322530",
  }, {
    name: "Recoleta",
    lat: -34.592961,
    lng: -58.401699,
    address: "Av. Pueyrredón 1541",
    openAt: "Abierto todos los días",
    telephone: "11 3167 5204",
    whatsappLink: "5491131675204",
  }, {
    name: "Monte Castro",
    lat: -34.618887,
    lng: -58.504109,
    address: "Av. Álvarez Jonte 4648",
    openAt: "Lunes a sábados de 10 a 20hs",
    telephone: "11 5850 4662",
    whatsappLink: "5491158504662",
  }],
}, {
  zone: "Provincia de Buenos Aires", sucursales: [{
    name: "Castelar",
    lat: -34.650753,
    lng: -58.644282,
    address: "Gob. Inocencio Arias 2489",
    openAt: "Abierto todos los días",
    telephone: "11 7133 0730",
    whatsappLink: "541171330730",
  }, {
    name: "San Isidro",
    lat: -34.469119,
    lng: -58.511033,
    address: "9 de Julio 380",
    openAt: "Abierto todos los días",
    telephone: "11 3086 2395",
    whatsappLink: "541130862395",
  }, {
    name: "Olivos",
    lat: -34.513299,
    lng: -58.489653,
    address: "Ugarte 1520",
    openAt: "Abierto todos los días",
    telephone: "11 5181 6880",
    whatsappLink: "541151816880",
  }, {
    name: "Tortugas",
    lat: -34.452085,
    lng: -58.726076,
    address: "Tortugas Open Mall, primer piso, local G-12",
    openAt: "Abierto todos los días",
    telephone: "11 5832 5050",
    whatsappLink: "541158325050",
  }, {
    name: "Canning",
    lat: -34.859833,
    lng: -58.503877,
    address: "Shopping Plaza Canning Ezeiza, local 108",
    openAt: "Abierto todos los días",
    telephone: "11 2654 9615",
    whatsappLink: "541126549615",
  }, {
    name: "La Plata",
    lat: -34.913264,
    lng: -57.947246,
    address: "Calle 5 número 937 (entre 50 y 51)",
    openAt: "Abierto todos los días",
    telephone: "221 675 2544",
    whatsappLink: "542216752544",
  }, {
    name: "Mar de las Pampas",
    lat: -37.326083,
    lng: -57.022512,
    address: "El Lucero y Santa María",
    openAt: "Abierto todos los días en temporada de verano",
    telephone: "2255 47 9590",
    whatsappLink: "542255479590",
  }, {
    name: "Villa Gesell",
    lat: -37.254219,
    lng: -56.967279,
    address: "Av. 3 entre 102 y 104",
    openAt: "Abierto todos los días en temporada de verano",
    telephone: "2255 47 2340",
    whatsappLink: "542255472340",
  }, {
    name: "Morón",
    lat: -34.650473,
    lng: -58.620739,
    address: "Almirante Brown 871 local 3, Morón",
    openAt: "Abierto todos los días",
    telephone: "11 7889 3975",
    whatsappLink: "541178893975",
  }, {
    name: "Lomitas",
    lat: -34.766043,
    lng: -58.402124,
    address: "Italia 459, Lomas de Zamora Centro Comercial Las Lomitas Street Local 116",
    openAt: "Abierto todos los días",
    telephone: "11 5957 7956",
    whatsappLink: "541159577956",
  }, {
    name: "Tandil",
    lat: -37.326931,
    lng: -59.136357,
    address: "Gral. Rodríguez 550, Local 2",
    openAt: "Abierto todos los días",
    telephone: "2494520192",
    whatsappLink: "542494520192",
  }, {
    name: "City Bell",
    lat: -34.868907,
    lng: -58.043947,
    address: "13C N282 Local 5 Esquina Cantilo, La Plata",
    openAt: "Abierto todos los días",
    telephone: "2214354562",
    whatsappLink: "542214354562",
  }, {
    name: "Escobar",
    lat: -34.362291,
    lng: -58.797441,
    address: "Portal Escobar, Ruta prov Nro 25 1710, Escobar. Local 1017",
    openAt: "Abierto todos los días",
    telephone: "11 7101 5569",
    whatsappLink: "541171015569",
  }, {
    name: "Palmas del Pilar",
    lat: -34.447829,
    lng: -58.867890,
    address: "Las Magnolias 754, Pilar",
    openAt: "Viernes, sábados y vísperas de feriado de 10:00hs a 22:00hs\nDomingos a jueves de 10:00hs a 21:00hs",
    telephone: "1171015569",
    whatsappLink: "541171015569",
  }, {
    name: "San Justo Shopping",
    lat: -34.684711,
    lng: -58.557520,
    address: "Brigadier General Juan Manuel de Rosas 3910, local n°23, San Justo",
    openAt: "Lunes a Domingos de 10 a 22 hs.",
    telephone: "11 7122 6215",
    whatsappLink: "541171226215",
  }, {
    name: "San Miguel",
    lat: -34.542687,
    lng: -58.709638,
    address: "Paunero 1423",
    openAt: "Abierto todos los días",
    telephone: "11 2525 4177",
    whatsappLink: "541125254177",
  }],
}, {
  zone: "Interior del País", sucursales: [{
    name: "Córdoba Capital",
    lat: -31.425236,
    lng: -64.184539,
    address: "Obispo Oro 237",
    openAt: "Abierto todos los días",
    telephone: "351 786 1014",
    whatsappLink: "543517861014",
  }, {
    name: "Santa Fe Capital",
    lat: -31.640978,
    lng: -60.704888,
    address: "Peatonal San Martín 3031",
    openAt: "Abierto todos los días",
    telephone: "342 4065 221",
    whatsappLink: "543424065221",
  }, {
    name: "Rosario",
    lat: -32.945271,
    lng: -60.644580,
    address: "Córdoba 1571",
    openAt: "Abierto todos los días",
    telephone: "341 6930 789",
    whatsappLink: "543416930789",
  }, {
    name: "Nuevo Centro Shopping",
    lat: -31.412574,
    lng: -64.204626,
    address: "Av. Duarte Quirós 1400, Primer piso, Córdoba",
    openAt: "Abierto todos los días",
    telephone: "351 786 1014",
    whatsappLink: "543517861014",
  }, {
    name: "Mendoza",
    lat: -32.892334,
    lng: -68.841182,
    address: "9 de Julio 919",
    openAt: "Lunes a domingos de 9 a 19:30hs",
    telephone: "02616151665",
    whatsappLink: "54902616151665",
  }, {
    name: "Rosario",
    lat: -32.926547,
    lng: -60.668168,
    address: "Alto Rosario Shopping - Junín 501",
    openAt: "Lunes a domingos de 10 a 21hs",
    telephone: "341 6930 789",
    whatsappLink: "543416930789",
  }, {
    name: "Córdoba",
    lat: -31.419480,
    lng: -64.188544,
    address: "Av. Vélez Sarsfield 361, local 415. Patio Olmos Shopping",
    openAt: "Abierto todos los días",
    telephone: "351 786 1014",
    whatsappLink: "543517861014",
  }, {
    name: "Misiones, Posadas",
    lat: -27.365191,
    lng: -55.895526,
    address: "San Lorenzo 1620",
    openAt: "Lunes a viernes: 8.30 a 12.30hs y de 16.30 a 20.30hs., Sábado 9 a 12.30hs y 17 a 20.30hs. Domingo cerrado",
    telephone: "0376154661432",
    whatsappLink: "5437615461432",
  }],
}];

const DEFAULT_CENTER = {lat: -34.615796, lng: -58.5156988};
const DEFAULT_ZOOM = 12;
const DEFAULT_MAP_CONFIG = {
  center: DEFAULT_CENTER, zoom: DEFAULT_ZOOM, setBounds: true,
};

const Sucursales = () => {
  const labelComponentId = 'label-component';
  const helpers = new Helpers();
  const [checked, setChecked] = useState(!helpers.isMobile());
  const [selectedZone, setSelectedZone] = useState(sucursales[0].zone);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [userLocationMarker, setUserLocationMarker] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapConfig, setMapConfig] = useState(DEFAULT_MAP_CONFIG);

  const locations = [];
  sucursales.map(zona => {
    zona.sucursales.map(sucursal => {
      locations.push(sucursal);
    })
  })

  const checkSelectedZone = () => {
    setUserLocationMarker(null);
    const title = document.getElementById(labelComponentId)
    sucursales.map((sucursal, index) => {
      const node = document.getElementById(index);
      if (node && node.getBoundingClientRect().bottom < title.getBoundingClientRect().bottom) {
        setSelectedZone(sucursales[index].zone)
      }
    })
  }

  const selectLocation = (location) => {
    const obj = {
      zoom: 18,
      center: {
        lat: location.lat, lng: location.lng,
      },
      setBounds: false,
    };
    setUserLocationMarker(null);
    setMapConfig(null);
    setMapConfig(obj);
    setSelectedLocation(location);
    helpers.isMobile() && setChecked(!checked);
  }

  const onGoogleMapsLoaded = () => {
    setMapLoaded(true);
    setTimeout(() => {
      const input = document.getElementById('places-search');
      const options = {
        componentRestrictions: {country: "ar"}, // fields: ["address_components", "geometry", "icon", "name"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(input, options);
      window.google.maps.event.addListener(autocomplete, "place_changed", function () {

        // Segment results into usable parts.
        const place = autocomplete.getPlace();
        if (place && place.geometry && place.geometry.location) {
          const geo = {
            lat: place.geometry.location.lat(), lng: place.geometry.location.lng(),
          };
          setUserLocationMarker(geo);
          const checkbox = document.getElementById('switch');
          if (checkbox) {
            checkbox.checked = false;
          }
        }
      });
    }, 3000);
  }

  const setGeolocation = () => {
    const checkbox = document.getElementById('switch');

    if (checkbox && !checkbox.checked) {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        setMapLoaded(false);
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude, lng: position.coords.longitude,
          };
          setUserLocationMarker(pos);
          setMapLoaded(true);
        }, () => {
          setMapLoaded(true);
          alert('No hemos podido obtener tu ubicación');
        },);
      } else {
        setMapLoaded(true);
        // Browser doesn't support Geolocation
        alert('Tu navegador no soporta geolocalización');
      }
    }
  }


  return (<Wrapper libraries={["core", "places"]} apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                   callback={onGoogleMapsLoaded}>
    <div className="sucursales">
      <div className="top-title">
          <span className="conoce-nuestra-historia-1">
            Buscá tu sucursal más cercana
          </span>
      </div>
      <div className="row align-items-center" style={{maxWidth: 1280, margin: 'auto'}}>
        <div className="col-12 col-lg-4">
          <div className="locations-list">
            <input className="locations-list-nav--checkbox" type="checkbox" id="touch" checked={checked}/>
            <label onClick={() => {
              helpers.isMobile() && setChecked(!checked)
            }} for="touch" className="location-list-nav" id={labelComponentId}>
              <div className="location-list-nav--title">Sucursales</div>
              {checked && <div className="locations-list--zone-title">{selectedZone}</div>}
              <span className="location-list-nav--chevron"><i className="location-list-nav--chevron-icon"/></span>
            </label>
            <div className="locations-list-nav-dropdown-list" onScroll={checkSelectedZone}>
              {sucursales.map((sucursalZone, index) => {
                return (<div className="locations-list--zone">
                  <div id={index} className={`locations-list--zone-title`}
                       style={{display: index > 0 ? 'block' : 'none'}}>{sucursalZone.zone}</div>
                  <div className="sucursal-list">
                    {sucursalZone.sucursales.map((sucursal) => (<Sucursal
                      onClick={() => selectLocation(sucursal)}
                      address={sucursal.address}
                      locationName={sucursal.name}
                      telephone={sucursal.telephone}
                      whatsappLink={sucursal.whatsappLink}
                      style={sucursal.style}
                      active={sucursal.name === selectedLocation?.name}
                    />))}
                  </div>
                </div>);
              })}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 text-center mt-4 mt-lg-0">
          <div className="map-wrapper">
            <div className="locations-floating-box">
              <div className="row align-items-center justify-content-between">
                <div className="mb-3 mb-lg-0 col col-lg-4">
                  <div className="locations-floating-box-title">Activá tu ubicación</div>
                </div>
                <div className="mb-3 mb-lg-0 col-auto">
                  <input className="switch" type="checkbox" id="switch"/>
                  <label className="switch-label" htmlFor="switch" onClick={setGeolocation}>Toggle</label>
                </div>
                <div className="col-12 col-lg-6 places-search-container">
                  <input type="text" id="places-search" placeholder="Ingresá tu dirección"/>
                </div>
              </div>
            </div>
            {mapLoaded ?
              <GoogleMaps mapConfig={mapConfig} locations={locations} userLocationMarker={userLocationMarker}/> :
              <Spinner animation="border"/>}
          </div>
        </div>
      </div>
    </div>
  </Wrapper>);
};

export default Sucursales;
