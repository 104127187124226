import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import{ init, sendForm } from '@emailjs/browser';
init("user_H3bCy6JjwQWH3cvuju8hd");

const Franquicias = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [interestZone, setInterestZone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [captchaNotVerified, setCatpachaNotVerified] = useState(true);
  return (
    <div className="franquicias">
      <div className="top-section">
        <span className="familia-la-pinocha">
          ¡Sumate a La Pinocha Chocolates!
        </span>
        <span className="conoce-nuestra-historia-1">
          Formá parte de nuestras franquicias
        </span>
      </div>


      <div className="form-section position-relative">
        {loading &&
          <div className="loading-overlay">
            <p>{loadingText || 'Cargando...'}</p>
          </div>
        }
        <div className="text-section">
          <p class="style-text-section">
            La Pinocha Chocolates es una empresa en constante crecimiento. Si te
            interesa <br />
            adquirir una franquicia y formar parte de nuestra empresa, completá
            el siguiente formulario <br />y un asesor de ventas se pondrá en
            contacto a la brevedad.
          </p>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault(); // Prevents default refresh by the browser
          setLoading(true);
          sendForm(`service_wgcy3sl`, 'template_isqxaoi', e.target, 'user_H3bCy6JjwQWH3cvuju8hd')
            .then((result) => {
                setLoadingText('¡Mensaje Enviado!');
              },
              (error) => {
                alert("Ocurrió un error. Intente nuevamente más tarde.", error.text);
                setLoading(false);
              });
        }} className="contact-us-form">
          <input type="hidden" value="La Pinocha Web" name="from_name" />
          <input type="hidden" value="La Pinocha" name="to_name" />
          <div className="contact-us-center">
            <div className="form-item first">
              <label className="form-label">Nombre y apellido</label>
              <input
                name="fullName"
                value={fullName}
                className="contact-us-input"
                onChange={(event) => {
                  setFullName(event.target.value);
                }}
              />
            </div>
            <div className="form-item">
              <label className="form-label">Correo electrónico</label>
              <input
                name="email"
                value={email}
                className="contact-us-input"
                type="email"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
            <div className="form-item">
              <label className="form-label">Teléfono de contacto</label>
              <input
                name="telephone"
                value={telephone}
                className="contact-us-input"
                onChange={(event) => {
                  setTelephone(event.target.value);
                }}
              />
            </div>
            <div className="form-item">
              <label className="form-label">
                Zona de interés para la franquicia
              </label>
              <input
                name="interestZone"
                value={interestZone}
                className="contact-us-input"
                onChange={(event) => {
                  setInterestZone(event.target.value);
                }}
              />
            </div>
            <div className="form-item">
              <label className="form-label">Dejanos tu mensaje</label>
              <textarea
                name="message"
                value={message}
                className="contact-us-area"
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
              ></textarea>
            </div>
            <div className="form-footer">
              <ReCAPTCHA
                className="g-recaptcha"
                sitekey="6LdbW6AdAAAAADjDpOuG6ZpUh6j1-fSL2N8N37De"
                onChange={(token) => {
                  setCatpachaNotVerified(false);
                }}
                onErrored={(token) => {
                  setCatpachaNotVerified(true);
                }}
              />
              <button
                type="submit"
                className="footer-submit"
                disabled={captchaNotVerified || (!fullName || !email || !interestZone || !telephone || !message || loading)}
              >
                Enviar
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Franquicias;
