const ButtonSection = () => {
    return (
        
     <div className="botton-col" >
       <div className= "botton-img1">
       </div>
     </div>


        );
};

export default ButtonSection;