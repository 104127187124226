const Sucursal = ({onClick, locationName, address, telephone, whatsappLink, style, active = false}) => {
  return (
    <a onClick={onClick} className={"sucursal row align-items-center m-0 " + (active ? 'active' : '')} style={style}>
      <div className="col p-0">
        <div className="sucursal-title">{locationName}</div>
        <div className="sucursal-address">{address}</div>
        {telephone && <div>+54 {telephone}</div>}
      </div>
      {!whatsappLink && !telephone ? null :
        whatsappLink ?
          <div className="col-auto p-0">
            <a href={`https://wa.me/${whatsappLink}`} target="_blank" className="sucursal-icon whatsapp"></a>
          </div>
          :
          <div className="col-auto p-0">
            <a href={`tel://${telephone}`} target="_blank" className="sucursal-icon telephone"></a>
          </div>
      }
    </a>
  );
};

export default Sucursal;
